import { Collapse } from 'bootstrap';
import SmoothScroll from 'smooth-scroll';
import Splide from "@splidejs/splide";

(function ($, root, undefined) {
	
	$(function () {
		
		'use strict';
		console.log('ready');

		var scroll = new SmoothScroll('a[href*="#"]', {
			topOnEmptyHash: true,
			speed: 500,
			speedAsDuration: true,
			offset: 130,
			easing: 'easeInOutQuad',
		});

	});

	$(function() {
		var header = $(".navbar");

		$(window).scroll(function() {
			var scroll = $(window).scrollTop();
			if (scroll >= 10) {
				header.addClass("scrolled");
			} else {
				header.removeClass("scrolled");
			}
		});

	});

	// const mainSlider = document.querySelectorAll('.slider__items');
	//
	// if (mainSlider) {
	// 	mainSlider.forEach((slider)=>{
	// 		new Splide( slider, {
	// 			arrows: true,
	// 			height: '550px',
	// 			pagination: false,
	// 			autoWidth: true,
	// 			type: 'loop',
	// 			gap: '35px',
	// 			perPage: 1,
	// 			perMove: 1,
	// 			focus: "center",
	// 			breakpoints: {
	// 				768: {
	// 					height: '575px',
	// 					width: '100%',
	// 					autoWidth: false
	// 				},
	// 			},
	// 		} ).mount();
	// 	});
	// }

	const mainSlider = document.querySelectorAll('.slider__items');

	if (mainSlider) {
		mainSlider.forEach((slider)=>{
			new Splide( slider, {
				arrows: true,
				start: 0,
				height: '550px',
				pagination: false,
				autoWidth: true,
				fixedWidth: true,
				focus    : 'center',
				gap: '25px',
				breakpoints: {
					1366: {
						height: '475px',
					},
					768: {
						height: '575px',
						perPage: 1,
						perMove: 1,
						autoWidth: false
					},
				},
			} ).mount();
		});
	}


	// var elts = querySelector('.inputs')
	// Array.from(elts).forEach(function(elt) {
	// 	elt.addEventListener("keydown", function(event) {
	// 		// Number 13 is the "Enter" key on the keyboard
	// 		if (event.keyCode === 13 ||
	// 			event.keyCode !== 8 && elt.value.length === Number(elt.maxLength)
	// 		) {
	// 			// Focus on the next sibling
	// 			elt.nextElementSibling.focus()
	// 		}
	// 		if (event.keyCode == 8) {
	// 			elt.value = '';
	// 			if (elt.previousElementSibling != null) {
	// 				elt.previousElementSibling.focus();
	// 				elt.previousElementSibling.value = '';
	// 				event.preventDefault();
	// 			}
	// 		}
	// 	});
	// })

	// Look for .hamburger
	var hamburger = document.querySelector(".hamburger");
	// On click
	hamburger.addEventListener("click", function() {
		// Toggle class "is-active"
		hamburger.classList.toggle("is-active");
		hamburger.parentElement.classList.toggle('active')
		// Do something else, like open/close menu
	});


	var infoBar = document.querySelector(".cookies-infobar");
	var btnAccept = document.querySelector("#cookies-infobar-close");

	// Check if user has already accepted the notification
	if(wasAccepted()) {
		hideInfobar();
		return;
	}

	//listen for the click event on Accept button
	btnAccept.addEventListener("click", function (e) {
		e.preventDefault();
		hideInfobar();
		saveAcceptInCookies(7);
	});

	//hide cookie info bar
	function hideInfobar () {
		infoBar.className = infoBar.classList.value + " cookies-infobar_accepted";
	}

	// Check if user has already accepted the notification
	function wasAccepted () {
		return checkCookie() === "1";
	}

	// get cookie
	function checkCookie () {
		var name = "cookieInfoHidden=";
		var cookies = document.cookie.split(';');

		for(var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			while (cookie.charAt(0)==' ') {
				cookie = cookie.substring(1);
			}

			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}
		return "";
	}

	//save cookie
	function saveAcceptInCookies (daysOfValidity) {
		var now = new Date();
		var time = now.getTime() + (daysOfValidity * 24 * 60 * 60 * 1000);
		var newTime = new Date(now.setTime(time));

		newTime = newTime.toUTCString();

		document.cookie = "cookieInfoHidden=1; expires=" + newTime + "; path=/";
	}



})(jQuery, this);
